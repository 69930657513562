import { useEffect, useState } from "react";
import "./index.css";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assests/images/logo/Final_SVG.svg";
function Navbar() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  let links = [
    {
      name: "About Us",
      url: "/",
    },
    {
      name: "Products",
      url: "/photoframe",
    },
    {
      name: "Showcase",
      url: "show-case",
    },

    {
      name: "Features",
      url: "/",
    },
    // {
    //   name: "Technology",
    //   url: "/",
    // },
    // {
    //   name: "VIDEOS",
    //   url: "/",
    // },
    {
      name: "Contact Us",
      url: "/",
    },
  ];
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (location.pathname === "/") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);
  return (
    show && (
      <>
        <div className="container">
          <div className="" style={{ position: "relative" }}>
            <div className="Navbar">
              <NavLink to="/home">
                <img src={logo} className="logo" />
              </NavLink>
              <div className={`nav-items ${isOpen && "open"}`}>
                {links.map((val) => (
                  <NavLink
                    onClick={() => setIsOpen(false)}
                    className="link"
                    to={val.url}
                  >
                    {val.name}
                  </NavLink>
                ))}
              </div>
              <div
                className={`nav-toggle ${isOpen && "open"}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                  <div className="bar"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Navbar;
