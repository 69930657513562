import React, { useEffect, useState } from "react";
import "./index.css";
import { useLocation } from "react-router-dom";
import logo from "../../assests/images/logo/RealityScaleLogo.jpg";
const Footer = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  return (
    show && (
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="cta-text">
                    <h4>Find us</h4>
                    <span>Lorem ipsum dolor sit amet.</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fas fa-phone"></i>
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <span> Lorem ipsum dolor sit amet.</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="far fa-envelope-open"></i>
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <span> Lorem ipsum dolor sit amet.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="home">
                      <img
                      src={logo}
                      className="img-fluid"
                      alt="logo"
                    />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      Lorem ipsum dolor sit amet, consec tetur adipisicing elit,
                      sed do eiusmod tempor incididuntut consec tetur
                      adipisicing elit,Lorem ipsum dolor sit amet.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="#">
                      <i className="fab fa-facebook-f facebook-bg"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter twitter-bg"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-google-plus-g google-bg"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul className="">
                    <li>
                      <a href="/" className="anchr">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/" className="anchr">
                        about us
                      </a>
                    </li>
                    <li>
                      <a href="/photoframe" className="anchr">
                        Products
                      </a>
                    </li>
                    <li>
                      <a href="show-case" className="anchr">
                        Show Case
                      </a>
                    </li>
                    <li>
                      <a href="/" className="anchr">
                      Contact us
                      </a>
                    </li>
                    <li>
                      <a href="show-case" className="anchr">
                    Real State
                      </a>
                    </li>
                    <li>
                      <a href="show-case" className="anchr">
                    Features
                      </a>
                    </li>
                    <li>
                      <a href="show-case" className="anchr">
             AI tools
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>News Letter</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Lorem ipsum dolor sit amet, consec tetur adipisicing elit,
                      sed do eiusmod tempor.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        <i className="fab fa-telegram-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text mt-3">
                  <p>
                    Copyright &copy; 2018, All Right Reserved{" "}
                    <a href="https://www.digixito.com/">Digixito</a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu mt-3">
                  <ul>
                    <li>
                      <a href="#" className="anchr">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#" className="anchr">
                        Terms
                      </a>
                    </li>
                    <li>
                      <a href="#" className="anchr">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a href="#" className="anchr">
                        Policy
                      </a>
                    </li>
                    <li>
                      <a href="#" className="anchr">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  );
};

export default Footer;
