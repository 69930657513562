import React, { useState } from "react";
import ReactHorizontalTimeline from "react-horizontal-timeline";
import "./index.css";
import Component from "../displayComponent/component";
import SecondComponent from "../displayComponent/secondComponent";
import Third from "../displayComponent/third";
import Fourth from "../displayComponent/fourth";
import Fifth from "../displayComponent/fifth";
import Sixth from "../displayComponent/six";
import Seven from "../displayComponent/seven";
import Eight from "../displayComponent/Eight";
import Nine from "../displayComponent/nine";

const DisplayComponentA = () => <div>Component A</div>;
const DisplayComponentB = () => <div>Component B</div>;

const Range = () => {
  const customTexts = [
    new Date(1970, 0, 1),
    new Date(1970, 0, 2),
    new Date(1971, 0, 3),
    new Date(1972, 0, 4),
    new Date(1973, 0, 5),
    new Date(1974, 0, 6),
    new Date(1975, 0, 7),
    new Date(1976, 0, 8),
    new Date(1977, 0, 9),
  ];
  const [state, setState] = useState({
    value: 0,
    previous: 0,
  });

  const getDisplayComponent = (value) => {
    switch (value) {
      case 0:
        return <Component />;
      case 1:
        return <SecondComponent />;
      case 2:
        return <Third />;
      case 3:
        return <Fourth />;
      case 4:
        return <Fifth />;
      case 5:
        return <Sixth />;
      case 6:
        return <Seven />;
      case 7:
        return <Eight />;
      case 8:
        return <Nine />;
      default:
        return null;
    }
  };

  const timelineStyles = {
    background: "transparent",
    foreground: "#61dafb",
    outline: "#dfdfdf",
    date: {
      color: "white",
    },
  };

  const dateStyles = {
    display: "none",
    color: "white",
  };

  return (
    <div className="rnge soks">
      <div>
        <p className="busines text-center">Key Feature</p>
      </div>
      <div div className="container mt-5 pt-3">
        <div
          style={{
            width: "50%",
            height: "100px",
            margin: "0 auto",
            color: "white",
          }}
        >
          <ReactHorizontalTimeline
            index={state.value}
            indexClick={(index) =>
              setState({ value: index, previous: state.value })
            }
            values={customTexts}
            styles={{ ...timelineStyles, date: dateStyles, color: "#ffffff" }}
          />
        </div>
        <div className="text-center">{getDisplayComponent(state.value)}</div>
      </div>
    </div>
  );
};

export default Range;
