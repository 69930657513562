import React from "react";
import "./index.css";
import Button from "../../components/button";
import { FaArrowRight } from "react-icons/fa6";
import image1 from "../../assests/images/banner/Picture1.png";
const Keyfeature = () => {
  return (
    <section className="service-feature">
      <div className="heading white-heading f-15">Feature</div>
      <div className="wrap">
        <div className="container warps mt-5">
          <div className="row align-items-center">
            <div className="col-sm-6 ">
              <img
                src={image1}
                width="100%"
                className="rounded process_hover pr-right "
                alt=""
              />
            </div>
            <div className="col-sm-6 lormips">
              <div className="pl-left">
                <h2 className="onbord mb-4"> Lorem Ipsum</h2>
                <p className="skills_para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>

                <Button className="onbords">
                  READ MORE <FaArrowRight size={28} className="mx-3" />
                </Button>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 lormips">
              <div className="pr-right">
                <h2 className="onbord mb-4"> Lorem Ipsum</h2>
                <p className="skills_para  ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <Button className="onbords">
                  READ MORE <FaArrowRight />
                </Button>
              </div>
            </div>
            <div className="col-sm-6 lormips">
              <div className="">
                <img
                  src={image1}
                      width="100%"
                  className="rounded process_hover pl-left "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 lormips">
              <img
                src={image1}
                    width="100%"
                className="rounded process_hover pr-right "
                alt=""
              />
            </div>
            <div className="col-sm-6 lormips">
              <div className="pl-left">
                <h2 className="onbord mb-4"> Lorem Ipsum</h2>
                <p className="skills_para  ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <Button className="onbords">
                  READ MORE <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 lormips">
              <div className="pr-right">
                <h2 className="onbord mb-4"> Lorem Ipsum</h2>
                <p className="skills_para  ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <Button className="onbords">
                  READ MORE <FaArrowRight />
                </Button>
              </div>
            </div>
            <div className="col-sm-6 lormips">
              <div className="">
                <img
                  src={image1}
                      width="100%"
                  className="rounded process_hover pl-left "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 lormips">
              <img
                src={image1}
                    width="100%"
                className="rounded process_hover pr-right "
                alt=""
              />
            </div>
            <div className="col-sm-6 lormips">
              <div className="pl-left">
                <h2 className="onbord mb-4"> Lorem Ipsum</h2>
                <p className="skills_para  ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <Button className="onbords">
                  READ MORE <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 lormips">
              <div className="pr-right">
                <h2 className="onbord mb-4"> Lorem Ipsum</h2>
                <p className="skills_para  ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <Button className="onbords">
                  READ MORE <FaArrowRight />
                </Button>
              </div>
            </div>
            <div className="col-sm-6 lormips">
              <div className="">
                <img
                  src={image1}
                      width="100%"
                  className="rounded process_hover pl-left "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 lormips">
              <img
                src={image1}
                    width="100%"
                className="rounded process_hover pr-right "
                alt=""
              />
            </div>
            <div className="col-sm-6 lormips">
              <div className="pl-left">
                <h2 className="onbord mb-4"> Lorem Ipsum</h2>
                <p className="skills_para  ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <Button className="onbords">
                  READ MORE <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Keyfeature;
