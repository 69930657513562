import React, { useState, useEffect } from "react";
import "./index.css";
import Aos from "aos";
import Button from "../../components/button";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import one from "../../assests/images/side_image/2.jpg";
import two from "../../assests/images/side_image/3.jpg";
import three from "../../assests/images/side_image/4.jpg";
import four from "../../assests/images/side_image/5.jpg";
import five from "../../assests/images/side_image/6.jpg";
import six from "../../assests/images/side_image/7.jpg";
import Feature from "../feature";
import ShowCase from "../keyfeature";
import RangeSlider from "../../components/slide";
import Advantage from "../advantage";
import Keyfeature from "../keyfeature";
import video from "../../assests/images/video/Reality.mp4";
import Frame from "../photoframe";
import image1 from "../../assests/images/landing/cube.jpg";
import image2 from "../../assests/images/banner/greyscale.jpg";
import image3 from "../../assests/images/showcase/lava.jpg";
import image4 from "../../assests/images/showcase/monochrom.jpg";
import modern from "../../assests/images/landing/modern.jpg";
import lava from "../../assests/images/showcase/lava.jpg";
import Testimonal from "../testimonal";
import Strip from "../strip";
import Multicarousel from "../testimonal";
import Video from "../../components/video";
import RAnge from "../../components/rngecaroul";
import Range from "../../components/rngecaroul";


const Home = () => {
  const [timeRunning, setTimeRunning] = useState(1000);
  const [timeAutoNext, setTimeAutoNext] = useState(3000);

  let runNextAuto;

  useEffect(() => {
    runNextAuto = setTimeout(() => {
      showSlider("next");
    }, timeAutoNext);

    return () => clearTimeout(runNextAuto);
  }, [timeAutoNext]);

  const showSlider = (type) => {
    let carouselDom = document.querySelector(".carousel");
    let SliderDom = carouselDom.querySelector(".carousel .list");
    let thumbnailBorderDom = document.querySelector(".carousel .thumbnail");

    let SliderItemsDom = SliderDom.querySelectorAll(".carousel .list .item");
    let thumbnailItemsDom = document.querySelectorAll(
      ".carousel .thumbnail .item"
    );

    let runTimeOut;

    if (type === "next") {
      SliderDom.appendChild(SliderItemsDom[0]);
      thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
      carouselDom.classList.add("next");
    } else {
      SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
      thumbnailBorderDom.prepend(
        thumbnailItemsDom[thumbnailItemsDom.length - 1]
      );
      carouselDom.classList.add("prev");
    }

    clearTimeout(runTimeOut);
    runTimeOut = setTimeout(() => {
      carouselDom.classList.remove("next");
      carouselDom.classList.remove("prev");
    }, timeRunning);

    clearTimeout(runNextAuto);
    runNextAuto = setTimeout(() => {
      showSlider("next");
    }, timeAutoNext);
  };
  Aos.init({ able: "mobile" });
  return (
    <div className="waper">
      <div className="container-fluid p-0">
        {/* <video
          autoPlay
          playsInline
          loop
          muted
          className="video"
          style={{ pointerEvents: "none" }}
        >
          <source src={video} />
        </video> */}
        <div className="banner">
          <div className="bg-overlay">
            <aside class="leftsidetag">
              <span class="text-center get">GET SUPPORT</span>
              <span class="ms-3">|</span>
              <span class="text-center get ms-3">PRIVACY POLICY</span>
              <span class="ms-3">|</span>
              <span class="text-center get ms-3">GET IN TOUCH</span>
            </aside>
          </div>
        </div>
      </div>
      <Feature />

      

      {/* <-----start------------product-------------------------start------> */}

      <section className="service-section">
        <div className="container">
          <div className="heading white-heading">
            <span className="text-white">What We Do</span> Great Services
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block">
                <div className="inner-box">
                  <div className="imageservice">
                    <a href="/photoframe" className="anchortag">
                      <img src={modern} alt="" />
                    </a>
                    <div className="read-more">
                      <a href="/photoframe" className="anchortag">
                        Read More <i className="fas fa-angle-double-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="lower-content">
                    <h3 className="servicehead">
                      <a href="/photoframe" className="anchortag">
                        Services 1
                      </a>
                    </h3>
                    <div className="servicepara">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block">
                <div className="inner-box">
                  <div className="imageservice">
                    <a href="/photoframe" className="anchortag">
                      <img src={lava} alt="" />
                    </a>
                    <div className="read-more">
                      <a href="/photoframe" className="anchortag">
                        Read More <i className="fas fa-angle-double-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="lower-content">
                    <h3 className="servicehead">
                      <a href="/photoframe" className="anchortag">
                        Services 2
                      </a>
                    </h3>
                    <div className="servicepara">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block">
                <div className="inner-box">
                  <div className="imageservice">
                    <a href="/photoframe" className="anchortag">
                      <img src={modern} alt="" />
                    </a>
                    <div className="read-more">
                      <a href="/photoframe" className="anchortag">
                        Read More <i className="fas fa-angle-double-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="lower-content">
                    <h3 className="servicehead">
                      <a href="/photoframe" className="anchortag">
                        Services 3
                      </a>
                    </h3>
                    <div className="servicepara">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block">
                <div className="inner-box">
                  <div className="imageservice">
                    <a href="/photoframe" className="anchortag">
                      <img src={lava} alt="" />
                    </a>
                    <div className="read-more">
                      <a href="/photoframe" className="anchortag">
                        Read More <i className="fas fa-angle-double-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="lower-content">
                    <h3 className="servicehead">
                      <a href="/" className="anchortag">
                        Services 4
                      </a>
                    </h3>
                    <div className="servicepara">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block">
                <div className="inner-box">
                  <div className="imageservice">
                    <a href="/photoframe" className="anchortag">
                      <img src={modern} alt="" />
                    </a>
                    <div className="read-more">
                      <a href="/photoframe" className="anchortag">
                        Read More <i className="fas fa-angle-double-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="lower-content">
                    <h3 className="servicehead">
                      <a href="/photoframe" className="anchortag">
                        Services 5
                      </a>
                    </h3>
                    <div className="servicepara">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block">
                <div className="inner-box">
                  <div className="imageservice">
                    <a href="/photoframe" className="anchortag">
                      <img src={lava} alt="" />
                    </a>
                    <div className="read-more">
                      <a href="/photoframe" className="anchortag">
                        Read More <i className="fas fa-angle-double-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="lower-content">
                    <h3 className="servicehead">
                      <a href="/photoframe" className="anchortag">
                        Services 6
                      </a>
                    </h3>
                    <div className="servicepara">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <-----End------------product-------------------------End------> */}

      {/* <-----start------------Showcase-------------------------start------> */}
      <div className="carousel m-auto">
        <div className="list">
          <div className="item">
            <img src={image1} className="" />

            <div className="content">
              <div className="title"> Lorem</div>
              <div className="topic">Ipsum</div>
              <div className="des">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid assumenda facere ab
                et quasi ducimus aut doloribus non numquam. Explicabo,
                laboriosam nisi reprehenderit tempora at laborum natus unde. Ut,
                exercitationem eum aperiam illo illum laudantium?
              </div>
            </div>
          </div>
          <div className="item">
            <img src={image2} />

            <div className="content">
              <div className="title"> Lorem</div>
              <div className="topic">Ipsum</div>
              <div className="des">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid assumenda facere ab
                et quasi ducimus aut doloribus non numquam. Explicabo,
                laboriosam nisi reprehenderit tempora at laborum natus unde. Ut,
                exercitationem eum aperiam illo illum laudantium?
              </div>
            </div>
          </div>
          <div className="item">
            <img src={image3} />

            <div className="content">
              <div className="title"> Lorem</div>
              <div className="topic">Ipsum</div>
              <div className="des">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid assumenda facere ab
                et quasi ducimus aut doloribus non numquam. Explicabo,
                laboriosam nisi reprehenderit tempora at laborum natus unde. Ut,
                exercitationem eum aperiam illo illum laudantium?
              </div>
            </div>
          </div>
          <div className="item">
            <img src={image4} />

            <div className="content">
              <div className="title"> Lorem</div>
              <div className="topic">Ipsum</div>
              <div className="des">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid assumenda facere ab
                et quasi ducimus aut doloribus non numquam. Explicabo,
                laboriosam nisi reprehenderit tempora at laborum natus unde. Ut,
                exercitationem eum aperiam illo illum laudantium?
              </div>
            </div>
          </div>
        </div>

        <div className="thumbnail">
          <div className="item">
            <img src={image1} />
            <div className="content">
              <div className="title">Key Feature</div>
              <div className="description">Description</div>
            </div>
          </div>
          <div className="item">
            <img src={image2} />
            <div className="content">
              <div className="title">Key Feature</div>
              <div className="description">Description</div>
            </div>
          </div>
          <div className="item">
            <img src={image3} />
            <div className="content">
              <div className="title">Key Feature</div>
              <div className="description">Description</div>
            </div>
          </div>
          <div className="item">
            <img src={image4} />
            <div className="content">
              <div className="title">Key Feature</div>
              <div className="description">Description</div>
            </div>
          </div>
        </div>
      </div>
      {/* <-----End------------showcase-------------------------End------> */}

      <Keyfeature />
      <Strip />
      <div className="gridpic">
        <div className="container">
          <div className="row ">
            <div className="col-sm-4">
              <div className="">
                <img src={one} className="imageone" />
              </div>
            </div>
            <div className="col-sm-4 imgageupdown">
              <img src={two} className="imageone" />
            </div>
            <div className="col-sm-4 imgageupdown">
              {" "}
              <img src={three} className="imageone" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-4">
              {" "}
              <img src={four} className="imageone" />
            </div>
            <div className="col-sm-4 imgageupdown">
              <img src={five} className="imageone" />
            </div>
            <div className="col-sm-4 imgageupdown">
              <img src={six} className="imageone" />
            </div>
          </div>
        </div>
      </div>

      {/* <RangeSlider /> */}

      
      <Video />
      <Range />

      <Multicarousel />

      <Advantage />
    </div>
  );
};
export default Home;
