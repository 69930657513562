import React, { useState, useEffect } from "react";
import "./index.css";
import image1 from "../../assests/images/human/download.jpg";
import image2 from "../../assests/images/human/christopher-campbell-rDEOVtE7vOs-unsplash.jpg";
import image3 from "../../assests/images/human/imansyah-muhamad-putera-n4KewLKFOZw-unsplash.jpg";
import image4 from "../../assests/images/human/jeffery-erhunse-Z9lbmEjyYjU-unsplash.jpg";

const Multicarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const btns = document.querySelectorAll(".btn");
    const slideRow = document.getElementById("slide-row");
    const main = document.querySelector("main");
  
    const updateSlide = () => {
      const mainWidth = main.offsetWidth; // Removed unnecessary comment
      const translateValue = currentIndex * -mainWidth;
      slideRow.style.transform = `translateX(${translateValue}px)`;
  
      btns.forEach((btn, index) => {
        btn.classList.toggle("active", index === currentIndex);
      });
    };
  
    btns.forEach((btn, index) => {
      btn.addEventListener("click", () => {
        setCurrentIndex(index);
        updateSlide();
      });
    });
  
    updateSlide();
  
    return () => {
      btns.forEach((btn, index) => {
        btn.removeEventListener("click", () => {
          setCurrentIndex(index);
          updateSlide();
        });
      });
    };
  }, [currentIndex]);
  

  return (
    <>
      <div className="disav">
        <main>
          <h1>Testimonials</h1>

          <div className="slider">
            <div className="slide-row" id="slide-row">
              <div className="slide-col">
                <div className="loremcontant">
                  <p>
                    lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                    sequi, rem magnam nesciunt minima placeat, itaque eum neque
                    officiis unde, eaque optio ratione aliquid assumenda facere
                    ab et quasi ducimus aut doloribus non numquam.
                  </p>
                  <h2>Lorem Ipsum</h2>
                  <p>Manager</p>
                </div>
                <div className="hero">
                  <img src={image1} alt="avatar" />
                </div>
              </div>

              <div className="slide-col">
                <div className="loremcontant">
                  <p>
                    lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                    sequi, rem magnam nesciunt minima placeat, itaque eum neque
                    officiis unde, eaque optio ratione aliquid assumenda facere
                    ab et quasi ducimus aut doloribus non numquam.
                  </p>
                  <h2>Lorem Ipsum</h2>
                  <p>Treymont Inc.</p>
                </div>
                <div className="hero">
                  <img src={image2} alt="avatar" />
                </div>
              </div>

              <div className="slide-col">
                <div className="loremcontant">
                  <p>
                    lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                    sequi, rem magnam nesciunt minima placeat, itaque eum neque
                    officiis unde, eaque optio ratione aliquid assumenda facere
                    ab et quasi ducimus aut doloribus non numquam.
                  </p>
                  <h2>Lorem Ipsum</h2>
                  <p>Manager</p>
                </div>
                <div className="hero">
                  <img src={image3} alt="avatar" />
                </div>
              </div>

              <div className="slide-col">
                <div className="loremcontant">
                  <p>
                    lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                    sequi, rem magnam nesciunt minima placeat, itaque eum neque
                    officiis unde, eaque optio ratione aliquid assumenda facere
                    ab et quasi ducimus aut doloribus non numquam.
                  </p>
                  <h2>Lorem Ipsum</h2>
                  <p>Manager</p>
                </div>
                <div className="hero">
                  <img src={image4} alt="avatar" />
                </div>
              </div>
            </div>
          </div>

          <div className="indicatorbtn">
            {Array.from({ length: 4 }, (_, index) => (
              <span
                key={index}
                className={`btn ${index === currentIndex ? "active" : ""}`}
              ></span>
            ))}
          </div>
        </main>
      </div>
    </>
  );
};

export default Multicarousel;
