import React from "react";
import "./index.css";
import frame from "../../assests/images/banner/story.jpg";
const Feature = () => {
  return (
    <>
   <div className="logos">
<div className="logo_items">
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>

</div>
  <div className="logo_items">
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>
<img src="https://navneetdwivedi.github.io/Logo_Slider/logo.png"/>

</div>
</div>
 {/* <div className="bg-grey">
       <div className="container">
         <div class="text-center">
           <h2 class="feature">feature</h2>
           <span class="text-center performance">
             Lorem Ipsum has been the industry's standard dummy text ever since
             the 1500s, when an unknown printer took a galley.
           </span>
         </div>
       </div>
     </div> */}

    </>
//     <div className="bg-grey">
//       <div>
//         <p className="busines text-center mt-3">Features</p>
//       </div>
//       <article className="mt-5">
//         <section class="card">
//           <div class="text-content">
//           <h3 className="featurehead">
// Elevate Your Brand with Exceptional Design</h3>
//             <p className="featurepara">
//               Your website is often the first point of contact for potential
//               customers. We believe great design can help elevate your brand and
//               make a lasting impression.
//             </p>
//             <a href="#">Join now</a>
//           </div>
//           <div class="visual">
//             <img src={frame} />
//           </div>
//         </section>

//         <section class="card">
//           <div class="text-content">
//                           <h3 className="featurehead">
// Responsive Design for Every Screen Size</h3>
//             <p className="featurepara">
//               We specialize in creating responsive designs that look great on
//               desktop, tablet, and mobile devices, ensuring your site is
//               accessible to all your visitors.
//             </p>
//             <a href="#">Join now</a>
//           </div>
//           <div class="visual">
//             <img src={frame} />
//           </div>
//         </section>

//         <section class="card">
//           <div class="text-content">
//                           <h3 className="featurehead">
// Design that Speaks to Your Audience</h3>
//             <p className="featurepara">
//               We work closely with you to understand your brand and your
//               customers, so we can create a website that speaks directly to your
//               target audience.
//             </p>
//             <a href="#">Join now</a>
//           </div>
//           <div class="visual">
//             <img src={frame} />
//           </div>
//         </section>

//         <section class="card">
//           <div class="text-content">
//                           <h3 className="featurehead">
// Design that Drives Results for Your Business</h3>
//             <p>
//               We specialize in creating websites that not only look great but
//               also drive traffic and conversions. Let us help you create a
//               website that delivers real business value.
//             </p>
//             <a href="#">Join now</a>
//           </div>
//           <div class="visual">
//             <img src={frame} />
//           </div>
//         </section>
//         <section class="card">
//           <div class="text-content">
//                           <h3 className="featurehead">
// Elevate Your Brand with Exceptional Design</h3>
//             <p className="featurepara">
//               Your website is often the first point of contact for potential
//               customers. We believe great design can help elevate your brand and
//               make a lasting impression.
//             </p>
//             <a href="#">Join now</a>
//           </div>
//           <div class="visual">
//             <img src={frame} />
//           </div>
//         </section>
        
//         <section class="card">
//           <div class="text-content">
//                           <h3 className="featurehead">
// Design that Drives Results for Your Business</h3>
//             <p className="featurepara">
//               We specialize in creating websites that not only look great but
//               also drive traffic and conversions. Let us help you create a
//               website that delivers real business value.
//             </p>
//             <a href="#">Join now</a>
//           </div>
//           <div class="visual">
//             <img src={frame} />
//           </div>
//         </section>
//       </article>
//     </div>

  );
};

export default Feature;
