import React from "react";

const Seven = () => {
return (
<div class="container  p-0 events-content">
<ol className="listol">
        <li class="selected" data-date="16/01/2014">
            <h2> Timeline Horizontal</h2>
            <em> Lorem ipsum dolor</em>
            <p className="mt-2"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum
                praesentium officia, fugit recusandae ipsa, quia velit nulla
                adipisci? Consequuntur aspernatur at, eaque hic repellendus sit
                dicta consequatur quae, ut harum ipsam molestias maxime non nisi
                reiciendis eligendi! Doloremque quia pariatur harum ea amet
                quibusdam quisquam, quae, temporibus dolores porro doloribus.
            </p>
        </li>
    </ol>
</div>
);
};

export default Seven;